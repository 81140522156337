@tailwind base;
@tailwind components;
@tailwind utilities;



swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.what{
  
  background-image: url("./assets/whatsapp_logo_icon_229310.png");
  background-repeat: no-repeat;
  background-size:cover; 
  background-position: center;
  position: fixed;
bottom: 2em;
  right: 1em;
  z-index: 99999;
}
.hide-type{
  color: transparent;
}

