@tailwind base;
@tailwind components;
@tailwind utilities;

.text-white {
  background-image: url("../homemovil.jpg");
}

.image-logo{
    position: absolute;
    right: 50px;
    bottom: 25em;
    width: 330px;
    height: 200px !important;
}
